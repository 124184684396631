import React from "react";
import "./Header.css";
import Headerimage from "../../images/bitconewinlogo.png";
function Header() {
  return (
    <div className="header">
      <h1>BITCONE.WIN</h1>
      {/*<img src={Headerimage} alt="Header-Logo" className="Header-image" />*/}
    </div>
  );
}

export default Header;
